import { Outlet } from '@remix-run/react';
import { ToastContainer } from 'react-toastify';

import { useUserAnalytics } from '../analytics/user';
import { UserAccess } from '../components/Access/UserAccess';
import {
  ConfirmCancelModalProvider,
  ConfirmCancelModalRoot,
} from '../components/ConfirmCancelModalContext';
import { ProvidersList } from '../components/ProvidersList';
import { LearningLayout } from '../components/Training/Admin/Layout/LearningLayout';
import { UserContextProvider } from '../components/UserContext';
import { setAPIServiceClientSecureToken } from '../utils/setAPIClientToken';

setAPIServiceClientSecureToken();

export function Component() {
  const providers = [
    <UserContextProvider useUserAnalytics={useUserAnalytics} />,
    <UserAccess />,
    <ConfirmCancelModalProvider />,
  ];

  return (
    <ProvidersList providers={providers}>
      <LearningLayout>
        <Outlet />
      </LearningLayout>
      <ConfirmCancelModalRoot />
      <ToastContainer position='bottom-right' theme='dark' />
    </ProvidersList>
  );
}
